var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loaded)?_c('flixLoader'):_c('b-flix-calendarpicker',{staticStyle:{"background":"transparent","margin":"0 auto"},attrs:{"color":_vm.getBgColor(),"value":_vm.$store.getters.calendarDate
        ? _vm.$store.getters.calendarDate.getFullYear() +
          '-' +
          (_vm.$store.getters.calendarDate.getMonth() + 1) +
          '-' +
          _vm.$store.getters.calendarDate.getDate()
        : false,"values":_vm.fixShowToday(),"mode":_vm.id === '*' ? 'fix' : '',"settings":{
      hover: true,
      shadow: false,
      navigation: true,
      style: 'rounded'
    }},on:{"save":_vm.getSetDate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }