<template>
  <div>
    <flixLoader v-if="!loaded" />
    <b-flix-calendarpicker
      v-else
      :color="getBgColor()"
      style="background: transparent; margin: 0 auto"
      :value="
        $store.getters.calendarDate
          ? $store.getters.calendarDate.getFullYear() +
            '-' +
            ($store.getters.calendarDate.getMonth() + 1) +
            '-' +
            $store.getters.calendarDate.getDate()
          : false
      "
      :values="fixShowToday()"
      :mode="id === '*' ? 'fix' : ''"
      :settings="{
        hover: true,
        shadow: false,
        navigation: true,
        style: 'rounded'
      }"
      @save="getSetDate"
    >
    </b-flix-calendarpicker>
  </div>
</template>
<script>
import nextAppointments from '@/components/overview/next'
export default {
  extends: nextAppointments,
  data() {
    return {
      id: this.$route.params.id || '*'
    }
  },
  methods: {
    fixShowToday() {
      const nextDates = Object.keys(this.nextDates)
      if (!nextDates.includes(this.$route.params.date + ' 00:00:00')) {
        nextDates.push(this.$route.params.date + ' 00:00:00')
      }
      return nextDates
    },
    getBgColor() {
      if (
        typeof this.$store.state.assistents.assistentActive === 'object' &&
        typeof this.$store.state.assistents.assistentActive.color === 'string'
      ) {
        return this.$store.state.assistents.assistentActive.color
      }
      return '#42bd84'
    }
  }
}
</script>
